@import '../../assets/styles/mixins.scss';

.topicContainer {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-bottom: 50px;
    list-style: none;

    a {
        text-decoration: none;
    }
}

.btn {
    @include HoverBtn;
    @include LinkMenu(false);
    height: 58px;
    padding: 0 30px;
    border: 1px solid $text-c6;
    border-radius: 100px;
    box-sizing: border-box;
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;

    cursor: pointer;
}

.btnActive {
    border: 4px solid $bg-lblack;
}
