// author: Victor K.
@import '../../assets/styles/mixins.scss';

.header {
    @include ProjHead;
    padding-bottom: 40px;
    margin-top: 0;
    margin-bottom: 30px;
    border-bottom: 1px solid $text-c6;
}
