@import '../../assets/styles/mixins';

$color-primary: black;

.section {
    font-style: normal;
    font-weight: 300;
    font-size: 22px;
    line-height: 140%;
    letter-spacing: -0.02em;
    color: $color-primary;
    font-weight: normal;

    @include for-phone {
        font-size: 16px;
    }

    ol,
    ul {
        padding-left: 30px;
        margin: 30px 0;
    }

    ol li,
    ul li {
        list-style-type: inherit;
        font-weight: 300;
    }

    img {
        margin: 30px 0;
        width: 100%;
        height: auto;
    }

    // .short-info {
    //   font-size: 24px;
    //   line-height: 150%;
    //   letter-spacing: 0.02em;
    //   color: #9C9C9C;
    //   margin: 60px 0;
    // }

    // p {
    //   font-size: 16px;
    //   line-height: 150%;
    //   color: $color-primary;
    //   margin-bottom: 24px;
    // }

    // h1, h2, h3, h4, h5 {
    //   margin-bottom: 30px;
    //   margin-top: 60px;
    // }

    // h1 {
    //   font-size: 40px;
    // }

    // h2, h3, h4, h5 {
    //   font-size: 32px;
    // }

    // blockquote {
    //   margin: 60px 0;
    //   font-size: 16px;
    //   line-height: 150%;
    //   color: $color-primary;
    //   padding: 40px;
    //   background-color: #F6F6F6;

    //   p {
    //     font-size: inherit;
    //     line-height: inherit;
    //     margin: 0;
    //   }
    // }

    // .sub-scriptum {
    //   font-size: 16px;
    //   line-height: 150%;
    //   color: #969696;
    // }

    // iframe {
    //   min-height: 500px;
    // }
    // pre {
    //   white-space: pre-line;
    // }
}

@include for-tablet {
    // .section {
    //   .short-info {
    //     font-size: 20px;
    //     line-height: 150%;
    //     letter-spacing: 0.02em;
    //   }

    //   p,
    //   .text {
    //     font-size: 14px;
    //     line-height: 150%;
    //     color: $color-primary;
    //     margin-bottom: 24px;
    //   }

    //   h1, h2, h3, h4, h5 {
    //     margin-bottom: 30px;
    //     margin-top: 60px;
    //     line-height: 120%;
    //   }

    //   h1,
    //   .main-header {
    //     font-size: 30px;
    //   }

    //   h2, h3, h4, h5,
    //   .sub-header {
    //     font-size: 24px;
    //   }

    //   blockquote,
    //   .blockquote {
    //     font-size: 14px;
    //     padding: 40px 20px;
    //   }

    //   .sub-scriptum {
    //     font-size: 14px;
    //   }
    // }
}
