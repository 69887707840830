// firefox
$SB-color: rgba(0, 0, 0, 0);
$SB-bg: rgba(0, 0, 0, .7);
$SB-hover: rgba(0, 0, 0, .4);

html, body {
  scrollbar-color: $SB-color $SB-bg; /* «цвет ползунка» «цвет полосы скроллбара» */
  scrollbar-width: thin; /* толщина */
}

// webkit
* {
  ::-webkit-scrollbar {
    width: 8px; /* ширина для вертикального скролла */
    height: 8px; /* высота для горизонтального скролла */
    background-color: $SB-color;
  }
  
  /* ползунок скроллбара */
  ::-webkit-scrollbar-thumb {
    background-color: $SB-hover;
    border-radius: 40px;
    border: 1px solid white;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background-color: $SB-bg;
  }
  
  /* arrows */
  ::-webkit-scrollbar-button:vertical:start:decrement {
    display: none;
  }
  
  ::-webkit-scrollbar-button:vertical:end:increment {
    display: none;
  }
  
  ::-webkit-scrollbar-button:horizontal:start:decrement {
    display: none;
  }
  
  ::-webkit-scrollbar-button:horizontal:end:increment {
    display: none;
  }
}