/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.articleWrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 20px;

    @include for-desktop-sm {
        flex-direction: column;
        gap: 100px;
    }
    @include for-tablet {
        gap: 60px;
    }
}

.backLink {
    display: block;
    color: inherit;
    text-decoration: none;
    background: url('../../assets/images/arrow-15px.svg') no-repeat left center;
    background-size: 15px;
    padding-left: 25px;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 100%;
    letter-spacing: -0.02em;
}

.article {
    max-width: 910px;
    width: 100%;

    .articleHead {
        font-style: normal;
        font-weight: 400;
        font-size: 46px;
        line-height: 110%;
        letter-spacing: -0.03em;
        padding-bottom: 30px;

        @include for-tablet {
            font-size: 34px;
        }

        @include for-phone {
            font-size: 26px;
        }
    }

    .newsMeta {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        gap: 156px;
        margin-bottom: 100px;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 100%;
        letter-spacing: -0.02em;
        color: #000000;

        .extLink {
            background: url('../../assets/images/arrow-diag-10px.svg') no-repeat right
                center;
            background-size: 10px;
            padding-right: 25px;
        }

        @include for-phone {
            flex-direction: column;
            gap: 40px;
        }
    }
}
