/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.bigImg {
    width: 100%;
    overflow: hidden;

    img {
        width: 100%;
    }
}
