// author: Victor K.
@import '../../assets/styles/mixins.scss';

.input {
    border: 0;
    width: 100%;
    font-size: inherit;
    color: inherit;
    outline: none;
    &.filled {
        color: $text-main;
    }
}
