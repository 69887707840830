/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.headWrapper {
    margin-bottom: 100px !important;
}

.posterWrap {
    padding: 0 !important;
}
.poster {
    max-width: 100% !important;
}
