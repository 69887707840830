// author: Victor K.
@import '../../assets/styles/mixins.scss';

.CaseItem {
    // position: relative;
    width: 100%;
    // padding-top: calc(100% / 1.65);
    // height: -webkit-fill-available;
    text-decoration: none;
    opacity: 0;

    &.anim {
        @include AnimTransUp;
    }

    // &:hover {
    //   // text-decoration: underline;
    //   .preview {
    //     img {
    //       transform: scale(.9);
    //     }
    //   }
    // }

    .preview {
        // position: absolute;
        // top: 0;
        // left: 0;
        width: 100%;
        height: 100%;

        background-color: $bg-lgrey;
        margin-bottom: 30px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;

        img {
            width: 100%;
            transition: 0.3s all ease-in-out;
        }
    }
}

.wide {
    width: 100%;
}

.half {
    width: calc(50% - 15.5px);
}

.video {
    width: 100%;
}
