// author: Victor K.
@import '../../assets/styles/mixins.scss';

.feedbackForm {
    display: flex;
    flex-direction: column;
    max-width: 650px;
    margin-left: auto;
    margin-top: 100px;

    @include for-desktop-sm {
        margin-left: 0;
        margin-top: 60px;
    }
}

.attach {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 100%;
    letter-spacing: -0.02em;
    cursor: pointer;
    margin-bottom: 60px;

    @include for-tablet {
        font-size: 26px;
    }
}

.assign {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: -0.03em;
    margin-bottom: 60px;

    input {
        margin-right: 10px;
    }

    a {
        color: inherit;
    }

    @include for-tablet {
        font-size: 14px;
    }
}

.submit {
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 100%;
    letter-spacing: -0.02em;
    color: #ffffff;
    background-color: black;
    display: flex;
    width: 100%;
    height: 114px;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    border: none;
    cursor: pointer;

    @include for-tablet {
        height: 58px;
        font-size: 14px;
    }
}
