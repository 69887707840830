// author: Victor K.
@import '../../assets/styles/mixins.scss';

.projects {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, calc(50% - 20px));
    grid-column-gap: 40px;
    grid-row-gap: 60px;
    padding-top: 200px;

    .projectItem:nth-child(odd) {
        margin-top: -200px;
    }

    @include for-tablet {
        grid-template-columns: repeat(1, 1fr);
        padding-top: 0px;
        .projectItem:nth-child(odd) {
            margin-top: 0;
        }
    }
}

.showMore {
    @include LinkMenu(false);
    display: flex;
    align-items: center;
    height: 58px;
    width: fit-content;
    padding: 0 40px;
    border: 1px solid $text-c6;
    border-radius: 100px;
    box-sizing: border-box;
    background-color: white;
    @include HoverBtn;

    cursor: pointer;
    margin: 80px auto 0;
}

.skeleton {
    height: 760px;

    @include for-desktop-sm {
        height: 565px;
    }

    @include for-tablet {
        height: calc(115vw);
    }
}
