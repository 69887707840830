// author: Victor K.
@import './colors.scss';
@import './theme.scss';

@mixin AnimTransUp {
  // https://developer.mozilla.org/ru/docs/Web/CSS/animation-fill-mode
  opacity: 0;
  animation-name: TransUp;
  animation-duration: .5s;
  animation-timing-function: cubic-bezier(0.33, 0, 0.1, 0);
  animation-direction: normal;
  animation-fill-mode: forwards;
  @content;

  @keyframes TransUp {
    from {
      transform: translateY(20px);
      opacity: 0;
    } to {
      transform: translate(0);
      opacity: 1;
    }
  }
}

@mixin AnimDiagonal {
  animation: 6s Diagonal alternate infinite ease-in-out;
  @content;

  @keyframes Diagonal {
    from {
      transform: scale(1.2) translate(-5%,-5%);
    }
    to {
      transform: scale(1.2) translate(5%,5%);
    }
  }
}

@mixin AnimUnderline(
  $color: #000,
) {
  position: relative;
  &::after {
    transition-duration: .2s;
    transition-timing-function: cubic-bezier(0.33, 0, 0, 0);
    transition-property: width;
    position: absolute;
    content: '';
    left: 0;
    width: 0;
    height: 1px;
    bottom: -5px;
    background-color: $color;
  }
  &:hover::after {
    width: 100%;
  }
  @content;
}

@mixin HoverBtn() {
  &:hover {
    transition-duration: .5s;
    filter: invert(1);
    @content;
  }
}