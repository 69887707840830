// author: Victor K.
@import '../../../assets/styles/mixins.scss';

.circleLink {
    display: flex;
    width: 50px;
    height: 50px;
    justify-content: center;
    align-items: center;
    background-color: #f3f3f3;
    border-radius: 50px;

    i {
        width: 22px;
        height: 22px;
        background-image: url('../../../assets/images/links/links-sprite.svg');
        background-position: 0 0;
        background-repeat: no-repeat;
    }

    .be {
        background-position-x: -22px;
    }
    .ext {
    }

    &:hover {
        background-color: black;

        .be,
        .ext {
            background-position-y: -22px;
        }
    }
}
