// author: Victor K.
@import '../../assets/styles/mixins.scss';

.scrollToTop {
    display: flex;
    min-width: 57px;
    height: 57px;
    background-color: white;
    border-radius: 100px;

    position: fixed;
    right: -500px;
    top: calc(100vh - 107px);
    overflow: hidden;
    border: 1px solid $obj-border;
    box-sizing: border-box;
    z-index: 1000;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    gap: 10px;
    transition: all 0.3s;

    &:after {
        content: '';
        display: block;
        width: 20px;
        height: 20px;
        background-image: url('../../assets/images/scroll-to-top.svg');
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        transition: inherit;
    }

    &.active {
        transform: translateX(-550px);
    }

    &:hover {
        padding: 0 30px;
        &:after {
            transform: rotate(45deg) scale(80%);
        }

        .upText {
            display: initial;
        }
    }

    .notHover {
        .upText {
            display: none !important;
        }
    }

    .upText {
        display: none;
    }
}
