// author: Victor K.
@import '../../assets/styles/mixins.scss';

.blockWrapper {
    @include MainBlocksWrapper;
}

.blockContent {
    @include MainBlocksContent;
}

.blockTitle {
    @include SubTitle {
        margin-bottom: 30px;
    }
}

.blockDescr {
    @include Head2 {
        margin-bottom: 100px;
    }
}

.inView {
    opacity: 0;

    &.anim {
        @include AnimTransUp;
    }
}
