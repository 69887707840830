// author: Victor K.
@import '../../assets/styles/mixins.scss';

.footer {
    margin: 0 0 0 0 !important;
    background-color: $bg-black;
    color: $text-white;

    a {
        color: inherit;
        text-decoration: none;
    }
}

.copy {
    padding: 20px 0;
    color: $text-white;
    display: flex;
    justify-content: space-between;
    border-top: 1px solid white;
    @include Tag;
    color: #393939;

    @include for-phone {
        flex-direction: column;
        row-gap: 10px;
        font-size: 12px;
    }
}
