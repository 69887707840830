// author: Victor K.
@import '../../assets/styles/mixins.scss';

.field_file {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 100%;
    color: $text-c6;
    margin-bottom: 60px;
    padding-bottom: 20px;
    position: relative;

    label {
        font-style: normal;
        font-weight: 400;
        font-size: 36px;
        color: $text-main;
        letter-spacing: -0.02em;
        line-height: 100%;
        padding-top: 5px;
        position: absolute;
        cursor: pointer;
    }

    input {
        opacity: 0;
        border: 0;
        width: 100%;
        font-size: inherit;
        color: inherit;
        outline: none;
        cursor: pointer;
        &.filled {
            color: $text-main;
        }

        &* {
            cursor: pointer !important;
        }
    }

    .fileName {
        position: absolute;
        bottom: 10px;
        font-size: 12px;
        color: darken($text-main, $amount: 10);
    }

    @include for-tablet {
        font-size: 26px;
        margin-bottom: 40px;

        .fileName {
            bottom: 15px;
            font-size: 10px;
        }
    }

    // @include for-phone  {
    //   margin-left: 0;
    // }
}
