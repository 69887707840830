// author: Victor K.
@import '../../assets/styles/mixins.scss';

.field {
    position: relative;
    margin: 0 0 60px 0;

    @include for-tablet {
        margin: 0 0 40px 0;
    }
}

.title {
    color: #e0e0e0;
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 100%;
    margin: 0 0 30px 0;

    @include for-tablet {
        font-size: 26px;
    }
}

.params {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
    @include for-phone {
        gap: 10px;
    }

    .param {
        cursor: pointer;
        transition-duration: 0.5s;

        font-style: normal;
        font-weight: 500;
        line-height: 100%;
        letter-spacing: -0.02em;

        font-size: 16px;
        padding: 16px 30px;
        border-radius: 100px;
        background: #f3f3f3;

        &:hover {
            background: #e4e4e4;
        }
        &.selected {
            color: white;
            background: black;
        }

        @include for-tablet {
            font-size: 14px;
        }
        @include for-phone {
            font-size: 12px;
        }
    }
}

// .hiddenInput {
//   height: 0;
//   border: 0;
//   opacity: 0;
// }
