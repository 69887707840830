// author: Victor K.
// bg colors
$bg-lgrey: #F3F3F3;
$bg-lblack: #141414;
$bg-black: #000;

// objects colors
$obj-main: #000;
$obj-border: #C6C6C6;
$obj-lblack: #141414;
$obj-error: #FF4747;

// text colors
$text-main: #000;
$text-b3: #B3B3B3;
$text-c6: #C6C6C6;
$text-white: #fff;
$text-error: #FF4747;