// author: Victor K.
@import '../../assets/styles/mixins.scss';

.contacts {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    gap: 20px;
    padding: 100px 0;

    @include for-tablet {
        padding: 75px 0;
    }

    @include for-phone {
        padding: 50px 0 20px;
        flex-direction: column;
        row-gap: 40px;
    }

    a {
        @include LinkMenu(false);
        @include AnimUnderline($text-white);
        color: $text-white;

        @include for-tablet {
            font-size: 18px;
        }

        @include for-phone {
            font-size: 16px;
        }
    }

    .mailNphone,
    .socials {
        display: inherit;
        column-gap: 40px;

        @include for-desktop-sm {
            column-gap: 20px;
        }

        @media screen and (max-width: 860px) {
            flex-direction: column;
            row-gap: 15px;
        }
    }
}

.contacts.invert {
    padding: 0;
    align-items: flex-end;

    a {
        @include AnimUnderline($text-main);
        color: $text-main;
        &.big {
            font-size: 36px;
        }
    }

    @media screen and (max-width: 980px) {
        .mailNphone,
        .socials {
            flex-direction: row;
            column-gap: 20px;
        }
        a.big {
            font-size: 28px;
        }
    }

    @media screen and (max-width: 830px) {
        a.big {
            font-size: 22px;
        }
    }

    @include for-tablet {
        font-size: 20px;

        flex-direction: column;
        align-items: flex-start;
        row-gap: 15px;
        .mailNphone,
        .socials {
            flex-direction: column;
        }
        a.big {
            font-size: inherit;
        }
    }

    @include for-phone {
        font-size: 18px;
        a.big {
            font-size: inherit;
        }
    }
}
