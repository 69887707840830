// author: Victor K.
@import '../../assets/styles/mixins.scss';

.newsCard {
    .newsLink {
        text-decoration: none;
        color: initial;
    }

    .newsPoster {
        display: block;
        width: 100%;
        max-width: 660px;
        aspect-ratio: 1.46/1;
        background: #f1f1f1;
        object-fit: cover;
    }

    .newsTitle {
        margin: 30px 0 20px;
        font-style: normal;
        font-weight: 400;
        font-size: 28px;
        line-height: 110%;
        letter-spacing: -0.03em;
    }

    .newsDescription {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.03em;
        color: #b3b3b3;
    }

    .newsMeta {
        margin-top: 20px;
        border-top: 1px solid #d9d9d9;
        padding-top: 20px;

        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 110%;
        letter-spacing: -0.02em;

        display: flex;
        flex-direction: row;
        justify-content: space-between;
        gap: 20px;

        a {
            text-decoration: none;
            color: initial;
        }
    }
}
