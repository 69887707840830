// author: Victor K.
@import '../../assets/styles/mixins.scss';

.linkBigFeedback {
    display: block;
    @include ProjHead;
    text-decoration: none;

    @include for-desktop-sm {
        font-size: 11.5vw;
    }
}

.bigFeedback {
    margin: 0 !important;
    background-color: $bg-lgrey;
    padding: 60px 20px;
    position: relative;
    &::before {
        transition: 0.35s width ease-in-out;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 0;
        background-color: $bg-lblack;
        content: '';
    }

    &:hover {
        .content {
            filter: invert(1);
        }
        &::before {
            width: 100%;
        }
    }
}

.content {
    transition: 0.35s all ease-in-out;
    background-image: url('../../assets/images/big-arrow.svg');
    background-repeat: no-repeat;
    background-position: right center;

    @include for-desktop-sm {
        background-size: 11.5vw;
    }
}
