// author: Victor K.
@import '../../assets/styles/mixins.scss';

.nextProject {
    position: relative;

    a {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        justify-content: center;
        align-items: center;
        display: flex;
        text-decoration: none;
        background-color: rgba(0, 0, 0, 0.5);
    }
    span {
        @include AnimUnderline($text-main);

        color: $text-white;
        font-size: 7vw;

        padding-bottom: 10px;

        &::after {
            height: 3px;
            background-color: white;
            bottom: -10px;
        }
    }

    img {
        display: block;
        width: 100%;
        height: 90vh;
        background: lightgray;
        opacity: 0.8;
        z-index: -1;
        object-fit: cover;
    }
}
