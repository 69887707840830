/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.errPage {
    margin-bottom: 50px;

    > div {
        height: 40vh;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        // margin: 0;
    }
}

.link {
    display: flex;
    font-style: normal;
    font-weight: 500;
    font-size: 34px;
    line-height: 100%;
    letter-spacing: -0.02em;

    width: 450px;
    height: 114px;
    justify-content: center;
    align-items: center;
    border: 1px solid $text-c6;
    border-radius: 100px;
    text-decoration: none;
    color: $text-main;
}

.text {
    position: relative;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 32px;
    line-height: 120%;
    letter-spacing: -0.03em;

    &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        background-color: $text-main;
        z-index: -1;
    }

    span {
        background: $text-white;
    }
}
