/* * @Author: Victor K.  * @Last Modified by:   Victor K.  */
@import '../../assets/styles/mixins.scss';

.newsList {
    display: grid;
    width: 100%;
    grid-template-columns: repeat(2, calc(50% - 20px));
    grid-column-gap: 40px;
    grid-row-gap: 100px;

    @include for-tablet {
        grid-template-columns: repeat(1, 1fr);
    }

    @include for-phone {
        grid-row-gap: 80px;
    }
}
