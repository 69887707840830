// author: Victor K.
@import '../../assets/styles/mixins.scss';

.basicField {
    position: relative;

    .helpText {
        position: absolute;
        bottom: -30px;
        font-size: 12px;
        color: darken($obj-main, $amount: 10);

        &.error {
            color: $text-error;
        }
    }

    @include for-tablet {
        font-size: 26px;
        margin-bottom: 40px;

        .helpText {
            bottom: -25px;
            font-size: 10px;
        }
    }
}

.field {
    font-style: normal;
    font-weight: 400;
    font-size: 36px;
    line-height: 100%;
    color: $text-c6;
    margin-bottom: 60px;
    padding-bottom: 20px;
    border-bottom: 1px solid $text-c6;
}
