.headerL2 {
  font-style: normal;
  font-weight: 600 !important;
  font-size: 34px;
  line-height: 140%;
  letter-spacing: -0.02em;
  margin: 80px 0 30px;
}

.headerL3 {
  font-style: normal;
  font-weight: 600;
  font-size: 26px;
  line-height: 140%;
  letter-spacing: -0.02em;
  margin: 80px 0 30px;
}

p,
.articleText {
  font-style: normal;
  font-weight: 300;
  font-size: 22px;
  line-height: 140%;
  letter-spacing: -0.02em;
  color: #000000;
  margin: 30px 0;
}

.infoText {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.03em;
  color: #000000;
  padding-top: 30px;
  border-top: 1px solid #E5E5E5;
}

.quoteText {
  display: block;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 140%;
  letter-spacing: -0.03em;
  color: #000000;

  padding: 30px;
  margin: 80px 0;
  background: #F4F4F4;
}

a {
  color: #B3B3B3;
  /* text-decoration: none; */
  /* border-bottom: 1px solid #B3B3B3; */
}

strong, b {
  font-weight: bold;
}

/* for tablet */
@media screen and (max-width: 720px) {
  .headerL2 {
    font-size: 26px;
  }
  .headerL3 {
    font-size: 22px;
  }
}

/* for phone */
@media screen and (max-width: 480px) {
  .headerL2 {
    font-size: 20px;
  }
  .headerL3 {
    font-size: 18px;
  }
  p,
  .articleText {
    font-size: 16px;
  }

  .quoteText {
    font-size: 12px;
  }
}