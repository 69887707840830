// author: Victor K.
@import '../../assets/styles/mixins.scss';

.header {
    margin-top: 30px;
    @include for-desktop {
        padding: 0 50px;
        margin-bottom: 112px;
    }
    @include for-desktop-sm {
        margin-bottom: 100px;
    }
    @include for-tablet {
        margin-bottom: 85px;
    }
    @include for-phone {
        margin-bottom: 60px;
    }
}

.content {
    display: flex;
    max-width: 100%;
    justify-content: space-between;
    align-items: center;
    @include AnimTransUp;

    position: relative;
    z-index: 1;
}
