// author: Victor K.
@import '../../assets/styles/mixins.scss';

.burger {
    cursor: pointer;
    user-select: none;
    width: 30px;
    height: 30px;
    position: relative;
    overflow: hidden;
    display: none;

    &::before,
    &::after {
        position: absolute;
        display: block;
        content: '';
        width: 100%;
        height: 2px;
        background-color: $text-main;
        border-radius: 2px;

        animation-timing-function: cubic-bezier(0.33, 0, 0.1, 0);
        animation-duration: 0.5s;
        animation-direction: normal;
        animation-fill-mode: forwards;
    }

    &::before {
        transform-origin: left center;
        top: 10px;
        animation-name: outActiveBefore;
    }

    &::after {
        transform-origin: right center;
        right: 0;
        top: 20px;
        animation-name: outActiveAfter;
    }

    &.isActive {
        &::before,
        &::after {
            animation-duration: 0.8s;
        }
        &::before {
            animation-name: inActiveBefore;
        }

        &::after {
            animation-name: inActiveAfter;
        }
    }

    @include for-tablet {
        display: block;
        z-index: 100;
    }
}

@keyframes inActiveBefore {
    30% {
        width: 0;
    }
    49% {
        transform: rotate(0);
        top: 10px;
    }
    50% {
        top: -1px;
        transform: rotate(45deg);
        width: 0;
    }
    80%,
    100% {
        top: -1px;
        transform: rotate(45deg);
        width: 150%;
    }
}

@keyframes inActiveAfter {
    30% {
        width: 0;
    }
    69% {
        transform: rotate(0);
        top: 20px;
    }
    70% {
        top: -1px;
        transform: rotate(-45deg);
        width: 0;
    }
    100% {
        top: -1px;
        transform: rotate(-45deg);
        width: 150%;
    }
}

@keyframes outActiveBefore {
    0% {
        top: -1px;
        transform: rotate(45deg);
        width: 150%;
    }
    40%,
    50% {
        transform: rotate(0);
        top: 15px;
    }
}

@keyframes outActiveAfter {
    0% {
        top: -1px;
        transform: rotate(-45deg);
        width: 150%;
    }
    40%,
    50% {
        transform: rotate(0);
        top: 15px;
    }
}
