// author: Victor K.
@import '../../../assets/styles/mixins.scss';

.menu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    column-gap: 40px;
}

.menuItem {
    @include LinkMenu;

    @include for-tablet {
        font-size: 78px;
    }

    @include for-phone {
        font-size: 48px;
    }
}

.topFeedback {
    @include LinkMenu(false);
    display: flex;
    align-items: center;
    height: 58px;
    width: fit-content;
    padding: 0 40px;
    border: 1px solid $text-c6;
    border-radius: 100px;
    box-sizing: border-box;
    background-color: white;
    @include HoverBtn;

    @include for-tablet {
        width: 100%;
        font-size: 34px;
        height: 114px;
        place-content: center;
        margin-top: auto;
    }

    @include for-phone {
        font-size: 18px;
        height: 58px;
    }
}

@media screen and (max-width: 800px) {
    .menu {
        column-gap: 20px;
    }
    .topFeedback {
        padding: 0 20px;
    }
}

@include for-tablet {
    .menu {
        position: absolute;
        width: 100vw;
        height: 100vh;
        background-color: white;
        top: -30px;
        left: -20px;
        transform: translateX(-100%);
        transition: 0.5s;
        transition-property: transform;
        flex-direction: column;
        align-items: flex-start;
        justify-content: normal;
        padding: 60px 20px 100px;
        column-gap: 0;
        row-gap: 40px;
        box-sizing: border-box;

        &.openMobile {
            transform: translateX(0%);
            // display: block;
        }
    }
}

@include for-phone {
    .menu {
        top: -20px;
        left: -10px;

        .menu {
            row-gap: 30px;
        }
    }
}
